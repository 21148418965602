// extracted by mini-css-extract-plugin
export var we_are = "about-us-module--we_are--I0s7H";
export var we_are__title = "about-us-module--we_are__title--0g7PZ";
export var we_are__subtitle = "about-us-module--we_are__subtitle--B8iVQ";
export var imageCropper = "about-us-module--imageCropper--UlBzj";
export var image = "about-us-module--image--Prmpq";
export var missionSection = "about-us-module--missionSection--55J0t";
export var missionSection__list = "about-us-module--missionSection__list--0K-gg";
export var point = "about-us-module--point--dLVSw";
export var point__image = "about-us-module--point__image--9QhLn";
export var point__text = "about-us-module--point__text---XcMU";
export var point__title = "about-us-module--point__title--jh5rS";
export var point__paragraph = "about-us-module--point__paragraph--eGWXs";
export var core_value = "about-us-module--core_value--+glW7";
export var core_value__item = "about-us-module--core_value__item--LW0gH";
export var board = "about-us-module--board--4L2Fg";
export var board__title = "about-us-module--board__title--PNHXV";
export var member = "about-us-module--member--lPpJn";
export var member__detail = "about-us-module--member__detail--N4pqN";
export var member__image = "about-us-module--member__image--l4jIf";
export var member__name = "about-us-module--member__name--+S8E5";
export var member__position = "about-us-module--member__position--nHyr9";
export var member__about = "about-us-module--member__about--uZAit";
export var member__morelink = "about-us-module--member__morelink--TQ8+m";
export var member__info = "about-us-module--member__info--hTyAr";
export var newsletter = "about-us-module--newsletter--kav7S";
export var newsletter__title = "about-us-module--newsletter__title--lyhSo";
export var newsletterForm = "about-us-module--newsletterForm--2w5qv";
export var newsletterForm__input = "about-us-module--newsletterForm__input--Jwzdm";
export var newsletterForm__submit = "about-us-module--newsletterForm__submit--s6BKN";